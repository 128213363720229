import { Link, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import renderHTML from 'htmr';
import React, { useState } from 'react';
import BodyClassName from 'react-body-classname';

import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';

const FaqPage = (props) => {
  const {
    data: {
      bgFaq,
      bgContact,
      faqs: { nodes: faqs },
    },
  } = props;
  const [wpData, setWpData] = useState(faqs);
  const [activeFaqId, setActiveFaqId] = useState();

  return (
    <BodyClassName className="body-light page-faq">
      <DefaultLayoutComponent title="FAQ">
        <div className="site-main">
          <BackgroundImage
            Tag="section"
            className="page-banner bgImage bg-pos-center-top"
            fluid={bgContact.childImageSharp.fluid}
            preserveStackingContext
          >
            <div className="overlay" />
            <div className="wrapper wrapper-xl pd-x-md">
              <div className="section bg-light pd-t-0 pd-b-0 faq">
                <div className="wrapper wrapper-lg pd-x-sm">
                  <div className="faq-block pd-x-sm">
                    <BackgroundImage
                      className="faq-head bgImage"
                      fluid={bgFaq.childImageSharp.fluid}
                      preserveStackingContext
                    >
                      <h4>Questions? Look here.</h4>
                    </BackgroundImage>
                  </div>
                </div>
              </div>
            </div>
          </BackgroundImage>
          <div className="faq-container bg-dark">
            <div className="wrapper wrapper-xl pd-x-md">
              <div className="section bg-light pd-t-0">
                <div className="wrapper wrapper-lg pd-x-sm">
                  <div className="faq-body pd-x-sm">
                    {!!wpData?.length &&
                      wpData?.map((faq) => {
                        const [title] = faq?.data.title || { text: '' };
                        const descriptions = faq?.data?.description;

                        let totalDescriptions = [];
                        descriptions.map((elm) => totalDescriptions.push(elm.text));
                        return (
                          <div
                            key={faq?.id}
                            className={`faq-list ${faq?.id === activeFaqId ? 'active' : ''}`}
                            onClick={() => setActiveFaqId(faq?.id === activeFaqId ? null : faq.id)}
                          >
                            <h6 className={`faq-title ${faq?.id === activeFaqId ? 'current' : ''}`}>
                              {renderHTML(title.text)}
                            </h6>
                            {totalDescriptions?.map((fullDes) => (
                              <p
                                className="faq-content"
                                style={{
                                  display: faq.id === activeFaqId ? 'block' : 'none',
                                }}
                              >
                                {renderHTML(fullDes)}
                              </p>
                            ))}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  {
    bgFaq: file(relativePath: { eq: "bg-faq.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bgContact: file(relativePath: { eq: "bg-contact.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    faqs: allPrismicFaqs(sort: { fields: data___title___text }) {
      nodes {
        data {
          title {
            text
          }
          description {
            text
          }
        }
        id
      }
    }
  }
`;

export default FaqPage;
